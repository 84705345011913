<template>
  <Modal v-if="show" class="MasterManagerDialog" @close="handlerCancel">
    <template #header>
      <div class="MasterManagerDialog__Header">
        Confirmação de custos
      </div>
    </template>
    <template #body>
      <div v-if="message" class="MasterManagerDialog__Body">
        <div class="MasterManagerDialog__Text">
          <p>
            {{ message }}
          </p>
        </div>
      </div>

      <div v-else class="MasterManagerDialog__Body">
        <div class="MasterManagerDialog__Text">
          <p>
            Você deseja <strong>{{ action }}</strong> todos os custos baseados na sua busca ou somente os custos mostrados em tela?
          </p>
        </div>
        
        <div class="MasterManagerDialog__Actions">
          <BaseButton
            class="MasterManagerDialog__Actions--buttons"
            :label="`${action} todos`"
            color="dark"
            filled
            text-color="white"
            @click="handlerRequestCosts('allCosts', action)"
          />
          <BaseButton
            class="MasterManagerDialog__Actions--buttons"
            :label="`${action} custos em tela`"
            color="dark"
            filled
            text-color="white"
            @click="handlerRequestCosts('onScreen', action)"
          />
        </div>
      </div>
    </template>
  </Modal>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { Modal } from '@/components/molecules'
import { BaseButton } from '@/components/atoms'
import { financial } from '@/api'

export default {
  name: 'MasterManagerDialog',
  components: {
    Modal,
    BaseButton
  },
  
  data() {
    return {
      show: false,
      action: '',
      message: ''
    }
  },

  computed: {
    ...mapState({
      masterManagerApprove: state => state.financial.masterManagerApprove,
    }),
  },

  methods: {
    ...mapActions({
      setError: 'error/setError',
      setLoading: 'loading/setLoading',
    }),

    //Handlers
    handlerOpen(button) {
      this.action = button.label
      this.show = true
    },

    handlerCancel() {
      this.show = false
      this.message = ''
    },

    async handlerRequestCosts(type, operation) {
      this.show = false
      this.setLoading(true)
      const code = {
        'Reenviar': 2,
        'Reprovar': 3,
        'Aprovar': 13
      }
      
      const params = {
        statusCode: code[operation],
        body: {
          costs: this.masterManagerApprove.data.content.map(el => el.id)
        }
      }

      if (type === 'allCosts') delete params.body

      await financial.changeCostStatus(params, (res) => {
        this.$emit('fetchItems')
        this.$toast.success(res.message, { position: 'bottom-center' })
      }, (e) => {
        this.setError(e.validation?.status_code?.find(error => error))}, () => this.setLoading(false))
    }
  },
}
</script>
<style lang="scss" scoped>
.MasterManagerDialog {
  /deep/ .Modal__container {
    width: 600px;
    height: auto;
    @media #{$mobile-view} {
      width: 100%;
    }
  }

  &__Header {
    text-align: center;
    padding-top: 6px;
  }

  &__Text {
    text-align: center;
    padding: 1rem;
  }

  &__Actions {
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;

    &--buttons {
      width: 180px;
      margin: 5px;
      height: 30px;
    }
  }
}
</style>