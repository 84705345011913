<template>
  <main class="Financial">
    <TheSubHeader   
      ref="theSubHeader"
      icon="money"
      title="Financeiro"
      subtitle="Aprovação de Gestores"
      page-title-tag="Financial Master Manager Approve"
      type="routerTabs"
      organizer-gap="1rem"
    >
      <template #afterButtons>
        <BaseButton
          v-if="permissions && permissions.editVehicles && permissions.editVehicles.isVisible"
          icon="truck-delivered"
          color="primary-base"
          label="Tipo de Veículo"
          class="car-type-button"
          :track="trackSubheaderButtonString('Financial Master Manager Approve', 'Financial Edit Vehicle')"
          @click="goToEditVehicle()"
        />
        <BaseButton
          icon="status"
          color="primary-base"
          label="Custo de operação"
          class="operation-button"
          :track="trackSubheaderButtonString('Financial Master Manager Approve', 'Operation Cost')"
          @click="openModalOperation()"
        />
      </template>

      <template #filter>
        <section class="Financial__subheaderTab">  
          <!-- Drivers filter -->
          <BaseTreeSelect
            v-model="filters.driver"
            name="Motorista"
            label="Motorista / Beneficiário"
            placeholder="Pesquisar"
            multiple
            suggestions="searchDrivers"
            @input="updateTags(), handlerMasterManagerRequest()"
          />
          <!-- Type value filter -->
          <BaseTreeSelect
            v-model="filters.valueType"
            name="type"
            label="Tipo"
            :searchable="false"
            placeholder="Selecione"
            :enable-paste="false"
            :select-options="valueType"
            @input="handlerMasterManagerRequest()"
          />
          <!-- Stores filter -->
          <BaseTreeSelect
            v-model="filters.store"
            name="Cliente / Loja"
            label="Cliente / Loja"
            placeholder="Selecione"
            multiple
            :enable-paste="false"
            :select-options="null"
            :fetch-options="'stores/fetchStoresAsync'"
            @input="updateTags(), handlerMasterManagerRequest()"
          />

          <!-- Operation filter -->
          <BaseTreeSelect
            v-model="filters.operationType"
            name="Operation"
            label="Operação"
            placeholder="Selecione"
            :enable-paste="false"
            :select-options="operationType"
            @input="handlerMasterManagerRequest()"
          />
        </section>
      </template>

      <template v-if="allTags.length" #tag>
        <FiltersTag
          :tags="allTags"
          @removeTag="removeTag"
          @clear="clearAllTags"
        />
      </template>
    </TheSubHeader>

    <section class="Financial__content">
      <DataTable
        :header="masterManagerApprove.data.headers"
        :table="masterManagerApprove.data.content"
        :max-size="false"
        @input="selectItem"
        @select="setStatusValue"
        @comment="handlerChatMessenger"
      />

      <div class="Financial__operations">
        <div v-for="item in operations" :key="item.color" class="Financial__operations--item">
          <span class="circle" :class="`circle-${item.color}`" />
          {{ item.label }}
        </div>
      </div>

      <div class="Financial__tab--buttons">
        <!-- action buttons -->
        <BaseButton
          v-for="button in buttons"
          :key="button.id"
          class="action-button"
          :label="button.label"
          color="primary-base"
          filled
          text-color="white"
          :disabled="!masterManagerApprove.data.content.length"
          @click="handlerModal(button)"
        />

        <!-- confirm status button -->
        <BaseButton
          label="Confirmar Status"
          color="primary-base"
          class="action-button"
          filled
          text-color="white"
          :disabled="!hasValue"
          @click="confirmNewStatus"
        />
      </div>

      <Pagination @page="fetchMasterManager" @paginate="fetchMasterManager">
        <div v-if="masterManagerApprove.data.append" class="Financial__tab--cost">
          {{ masterManagerApprove.data.append.append_total_base_value }}
          <br>
          {{ masterManagerApprove.data.append.append_total_cost_value }}
          <br>
          <strong>{{ masterManagerApprove.data.append.end_day }}</strong>
        </div>  
      </Pagination>

      <ChatMessenger 
        ref="ChatMessenger" 
        :messages="analystsComments.data.content" 
        @fetchMessages="fetchMessages"
        @sendMessage="sendMessage"
      />

      <MasterManagerDialog ref="MasterManagerDialog" @fetchItems="fetchMasterManager" />
    </section>
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { financial } from '@/api'

//Components
import { DataTable, ChatMessenger, Pagination, FiltersTag } from '@/components/molecules'
import { TheSubHeader } from '@/components/organisms'
import { BaseButton, BaseTreeSelect } from '@/components/atoms'
import MasterManagerDialog from '@/views/financial/MasterManagerApprove/MasterManagerDialog'

//Mixins
import FinancialMixin from '@/mixins/financial'

export default {
  name: 'MasterManagerApprove',

  components: {
    TheSubHeader,
    BaseButton,
    FiltersTag,
    BaseTreeSelect,
    DataTable,
    ChatMessenger,
    Pagination,
    MasterManagerDialog
  },

  mixins: [FinancialMixin],
  
  data() {
    return {
      //Filters
      filters: {
        driver: [],
        store: [],
        valueType: null,
        operationType: null
      },

      // values options
      valueType: [
        {
          id: 0,
          name: 'Desconto',
          value: '<'
        },
        {
          id: 1,
          name: 'Acréscimo',
          value: '>='
        }
      ],

      // operation option
      operationType: [
        {
          id: '0',
          name: 'Offline'
        },
        {
          id: 1,
          name: 'Online'
        }
      ],

      //Tags
      allTags: [],

      value: [],
      inputValue: [],

      operations: [
        { label: 'Operação online', color: 'green' },
        { label: 'Operação offline', color: 'grey' }
      ],

      buttons: [
        {
          id: 0,
          label: 'Reenviar',
          action: 'SendAll',
        },
        {
          id: 1,
          label: 'Reprovar',
          action: 'DisapproveAll',
        },
        {
          id: 2,
          label: 'Aprovar',
          action: 'ApproveAll',
        },
      ]
    }
  },

  computed: {
    ...mapState({
      paginate: state => state.pagination.paginate,
      page: state => state.pagination.page,
      masterManagerApprove: state => state.financial.masterManagerApprove,
      analystsComments: state => state.financial.analystsComments,
      permissions: state => state.auth.permissions
    }),

    hasValue() {
      return !!this.value.length
    }
  },

  mounted() {
    this.handlerMasterManagerRequest()
  },

  methods: {
    ...mapActions({
      setLoading: 'loading/setLoading',
      setError: 'error/setError',
      setPagination: 'pagination/setPagination',
      fetchAnalystsComments: 'financial/fetchAnalystsComments',
      clearChatMessages: 'financial/clearChatMessages',
      clearPagination: 'pagination/clearPagination',
      fetchMasterManagerApprove: 'financial/fetchMasterManagerApprove',
    }),

    handlerModal(action) {
      this.$refs.MasterManagerDialog.handlerOpen(action)
    },

    async fetchMasterManager() {
      let payload = {
        obj: {
          beneficiary_id: this.filters.driver,
          store_id: this.filters.store,
          'additional_cost[signal]': this.filters.valueType?.value,
          'additional_cost[value]': this.filters.valueType?.value ? '0.0001' : null,
          type: this.filters.operationType
        },
        page: this.page,
        paginate: this.paginate
      }

      await this.fetchMasterManagerApprove(payload, (r) => r, (e) => e, () => this.setLoading(false))
      this.setPagination(this.masterManagerApprove)
    },

    async confirmNewStatus() {
      this.setLoading(true)

      const items = []

      for (let i in this.value) {
        const a = {
          id: this.value[i].id,
          status: this.formatInputStatus(this.value[i].inputs)
        }
        items.push(a)
      }

      const body = {
        costs: items
      }

      await financial.submitApproveMasterManager(body, async (r) => {
        await this.fetchMasterManager().then(() => {
          this.$toast.success(r.message, { position: 'bottom-center' })
        })
        this.value = []
      }, (e) => {
        for (let key in e?.validation) {
          this.setError(e?.validation?.[key]?.[0])
        }
      }, () => this.setLoading(false))
    },

    formatInputStatus(status) {
      for (let i in status ) {
        return status[i].optionSelected.id
      }
    },

    selectItem(data) {
      this.value = data
    },

    handlerChatMessenger(data) {
      this.clearChatMessages().then(() => this.$refs.ChatMessenger.open(data.id))
    },

    async fetchMessages(id) {
      await this.fetchAnalystsComments(id).then(() => {
        this.$refs.ChatMessenger.scrollMessages()
      })
    },

    async sendMessage(id, message) {
      this.setLoading(true)
      const body = {
        operation_cost_id: id,
        message
      }
      await financial.submitAnalystMessage(body, async () => {
        await this.fetchMessages(id)
        this.$refs.ChatMessenger.resetInput()
      }, (e) => e)
    },

    setStatusValue(data) {
      if(!data.inputs.find(el => el.optionSelected)) {
        this.$nextTick(() => {
          this.value = this.value.filter((v) => v.id !== data.id)
        })
      }

      this.value = this.value.filter((v) => v.id !== data.id)
      this.value.push(data)
    },

    handlerMasterManagerRequest() {
      this.clearPagination(20).then(() => this.fetchMasterManager())
    },

    removeTag(name, id){
      this.allTags = this.allTags.filter((tag) => tag.id !== id)
      this.filters.store = this.filters.store.filter((tag) => tag.name !== name)
      this.filters.driver = this.filters.driver.filter((tag) => tag.name !== name)
      this.handlerMasterManagerRequest()
    },

    clearAllTags(){
      this.allTags = []
      this.filters.store = []
      this.filters.driver = []
      this.handlerMasterManagerRequest()
    },

    updateTags(){
      this.allTags = [
        ...this.filters.store,
        ...this.filters.driver
      ]
    },
  }
}
</script>

<style lang="scss" scoped>
.Financial {
  &__subheaderTab {
    display: flex;
    align-items: flex-end;
    grid-gap: 1rem;

    @media #{$mobile-view} {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  &__modalHeader {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
  }

  &__content {
    display: flex;
    margin: 0 auto;
    max-width: 1180px;
    width: 100%;
    max-width: 1180px;
    flex-direction: column;
    padding: 0 0 3rem 0;
    @media (max-width: $viewport-lg) {
      padding: 0 1rem 3rem 1rem;
    }
  }

  &__operations {
    display: flex;
    margin-top: 1rem;
    gap: 20px;
    &--item {
      font-size: $font-size-3xsmall;
      gap: 5px;
      display: flex;
    }
  }

  .circle {
    height: 20px;
    width: 20px;
    border-radius: 100%;
    &-grey {
      background-color: $color-neutral-soft;
    }
    &-green {
      background-color: $color-success-soft;
    }
  }


  &__tab {
    &--buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;

    @media #{$mobile-view} {
      display: block;
      width: 100%;
    }

    .action-button {
      width: 150px;
      margin: 5px;
      height: 30px;

      @media #{$mobile-view} {
        width: 90%;
        margin: 10px auto;
      }

      &:last-child {
        margin-left: 80px;
        @media #{$mobile-view} {
          margin: 10px auto;
        }
      }
    }
    }

    &--cost {
      text-align: center;
      font-size: $font-size-mini;
      color: $color-neutral-stronger;
    }
  }

  /deep/ .vue-treeselect .vue-treeselect__control {
    background: transparent;

    .vue-treeselect__placeholder {
      color: $color-neutral-stronger;
    }
  }
}
</style>